import { useJwtHeaders } from '../../../hooks/useJwtHeaders';
import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import Optional from '../../Optional/Optional';
import axios from 'axios';
import { useNavigate } from 'react-router';
import './AdminMordyTotalsPage.scss';
import { calculateTotal } from '../../../helpers/calculateTotal';
import { setCustomer } from '../../../slices/customerSlice/customerSlice';
import camelcaseKeys from 'camelcase-keys';
import { getOrderAction } from '../../../actions/getOrderAction';

interface CustomerType {
  first_name: string;
  last_name: string;
}

interface AdminMordyType {
  amount: number;
  customer: CustomerType;
  id: number;
}

const AdminMordyTotalsPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headers = useJwtHeaders();
  const admin = useSelector<RootState, boolean>(({ user }) => user.admin!);
  const [orders, setOrders] = useState([] as AdminMordyType[]);

  useMemo(() => {
    axios.get(`/api/v1/admin/mordys`, headers).then(({ data }) => {
      setOrders(data);
    });
  }, []);

  const total = calculateTotal(orders, 'amount');

  const handleClick = (orderId, customer) => {
    dispatch(setCustomer(camelcaseKeys(customer)));
    dispatch(getOrderAction(orderId, navigate, headers));
  };

  return (
    <div className="admin-order-page">
      <Optional renderIf={admin}>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">Order # ({orders.length})</th>
              <th scope="col">Name</th>
              <th scope="col">Amount (${total})</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              <tr key={`mordy-${order.id}`} onClick={() => handleClick(order.id, order.customer)}>
                <td>{order.id}</td>
                <td>{`${order.customer.first_name} ${order.customer.last_name}`}</td>
                <td>${order.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Optional>
      <Optional renderIf={!admin}>
        <b>YOU MUST BE AN ADMIN TO VIEW THIS PAGE</b>
      </Optional>
    </div>
  );
};

export default AdminMordyTotalsPage;
